<template>
    <div id="pageTable">
        <v-container grid-list-xl fluid>
            <v-row class="pa-0 mt-0 mb-0">
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="pa-0 ">
                    <div style="display: flex; width:100%; height:100%; justify-content: flex-start;">
                        <hr class="vr"/>
                        <p class="tblName pb-2">Lotes</p>
                    </div>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="pa-0 d-flex justify-end">
                    <v-btn
                        color="transparent" 
                        @click="generarReporte('xlsx')"
                        rounded
                        class="btnReportes v-btn-excel elevation-0 pa-0"
                        style="min-width: 10px;"
                        :ripple="true"
                    >
                        <img src="/static/icon/xls.png" style="width:28px" />
                    </v-btn>
                    <v-btn
                        elevation="0"
                        class="btnNormalClaro ml-3"
                        @click="() => {}"
                    >
                        Validacion
                    </v-btn>
                    <v-btn
                        elevation="0"
                        class="btnNormalClaro ml-3"
                        @click="() => {}"
                    >
                        Enviar IMSS
                    </v-btn>
                    <BtnAcciones
                        :btnNombre="'Acciones'"
                        :btnColor="'color-58C5D8'"
                        class="ml-3"
                    >
                        <template v-slot:opciones>
                            <div
                                @click="!masivo ? '#':exportar('txt')" 
                                :style="!masivo? 'cursor:not-allowed;':''" 
                                class="btnAccionesItem py-2"
                                :class="[!masivo ? 'deshabilitado':'']"
                            >
                                <span>Formato .txt para IDSE</span>
                            </div>
                            <div 
                                @click="!masivo ? '#':exportar('pdf')" 
                                :style="!masivo? 'cursor:not-allowed;':''" 
                                class="btnAccionesItem py-2"
                                :class="[!masivo ? 'deshabilitado':'']"
                            >
                                <span>Exportar PDF</span>
                            </div>
                            <div 
                                @click="!masivo ? '#':exportar('sua')" 
                                :style="!masivo? 'cursor:not-allowed;':''" 
                                class="btnAccionesItem py-2"
                                :class="[!masivo ? 'deshabilitado':'']"
                            >
                                <span>Exportar SUA</span>
                            </div>
                            
                        </template>
                    </BtnAcciones>
                    <v-btn
                        height="40"
                        width="40"
                        fab
                        color="#FFFFFF"
                        class="elevation-0 mt-0 mr-3"
                        @click="abrirModalBuscar()"
                    >
                        <v-icon color="#96999A">refresh</v-icon>
                    </v-btn>
                    <v-btn
                        height="40"
                        width="40"
                        fab
                        color="#FFFFFF"
                        class="elevation-0 mt-0"
                        @click="abrirModalBuscar()"
                    >
                        <v-icon color="#96999A">mdi-magnify</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            <v-row class="mt-6">
                <v-col cols="12" xs="12" sm="12" md="12" class="div-card py-8" >
                    <div  style="overflow-x: auto;">

                        <v-row class="py-0 my-0 mt-5 mx-6" style="min-width: 700px;">
                            <div 
                                class="btn-lotes mr-2 d-flex justify-center align-center" 
                                :class="btnLotes == 'btn1' ? 'btn-activo':'btn-inactivo'"
                                @click="filtroLotes('btn1')"
                            >
                                <span class="ma-3">Procesados</span>
                            </div>
                            <div 
                                class="btn-lotes mr-2 d-flex justify-center align-center" 
                                :class="btnLotes == 'btn2' ? 'btn-activo':'btn-inactivo'"
                                @click="filtroLotes('btn2')"
                            >
                                <span class="ma-3">Lotes control</span>
                            </div>
                            <div 
                                class="btn-lotes mr-2 d-flex justify-center align-center" 
                                :class="btnLotes == 'btn3' ? 'btn-activo':'btn-inactivo'"
                                @click="filtroLotes('btn3')"
                            >
                                <span class="ma-3">Bajas</span>
                            </div>
                            <div 
                                class="btn-lotes mr-2 d-flex justify-center align-center" 
                                :class="btnLotes == 'btn4' ? 'btn-activo':'btn-inactivo'"
                                @click="filtroLotes('btn4')"
                            >
                                <span class="ma-3">Rechazados</span>
                            </div>
                            <div 
                                class="btn-lotes mr-2 d-flex justify-center align-center" 
                                :class="btnLotes == 'btn5' ? 'btn-activo':'btn-inactivo'"
                                @click="filtroLotes('btn5')"
                            >
                                <span class="ma-3">PDF Individual</span>
                            </div>
                            <div 
                                class="btn-lotes mr-2 d-flex justify-center align-center" 
                                :class="btnLotes == 'btn6' ? 'btn-activo':'btn-inactivo'"
                                @click="filtroLotes('btn6')"
                            >
                                <span class="ma-3">CTA Individual</span>
                            </div>
                            <div 
                                class="btn-lotes mr-2 d-flex justify-center align-center" 
                                :class="btnLotes == 'btn7' ? 'btn-activo':'btn-inactivo'"
                                @click="filtroLotes('btn7')"
                            >
                                <span class="ma-3">Movimientos</span>
                            </div>
                            <div 
                                class="btn-lotes mr-2 d-flex justify-center align-center" 
                                :class="btnLotes == 'btn8' ? 'btn-activo':'btn-inactivo'"
                                @click="filtroLotes('btn8')"
                            >
                                <span class="ma-3">Sincronización</span>
                            </div>
                        </v-row>

                        <v-row class="pa-0 ma-0 mx-6 mt-5" >
                            <v-col cols="12" xs="12" sm="12" md="12" class="px-5 py-2 border-tabla1" style="min-width: 700px;">
                                <table class="tbl-collapse ">
                                    <thead>
                                        <tr class="pa-0 ma-0">
                                            <th width="25%" class="tbl1-title pt-3">
                                                Lote
                                            </th>
                                            <th width="25%" class="border-left tbl1-title pl-2 pt-3">
                                                R.F.C.
                                            </th>
                                            <th width="25%" class="border-left tbl1-title pl-2 pt-3">
                                                Registro Patronal
                                            </th>
                                            <th width="25%" class="border-left tbl1-title pl-2 pt-3">
                                                Fecha y hora de envío
                                            </th>
                                        </tr>
                                        <tr class="pa-0 ma-0">
                                            <th class="tbl1-subtitle pb-3">
                                                432
                                            </th>
                                            <th class="border-left tbl1-subtitle pl-2 pb-3">
                                                CERN019283746
                                            </th>
                                            <th class="border-left tbl1-subtitle pl-2 pb-3">
                                                Hacienda Teya S.A. de C.V.
                                            </th>
                                            <th class="border-left tbl1-subtitle pl-2 pb-3">
                                                DD/MM/AAAA - 16:30 horas
                                            </th>
                                        </tr>
                                    </thead>
                                </table>
                            </v-col>
                        </v-row>

                        <v-row class="pa-0 ma-0 mt-6">
                            <v-col cols="12" xs="12" sm="12" md="12" class="px-5 py-2 border-tabla2" style="min-width: 700px;">
                                <table class="tbl-collapse tbl2">
                                    <thead>
                                        <tr class="pa-0 ma-0">
                                            <th colspan="4" class="tbl2-title py-2" style="border-top-left-radius: 15px; border-bottom: 1px solid #E0E0E0;">
                                                Recibidos
                                            </th>
                                            <th colspan="4" class="tbl2-title py-2 border-left " style="border-bottom: 1px solid #E0E0E0;">
                                                Operados
                                            </th>
                                            <th colspan="4" class="tbl2-title py-2 border-left" style="border-top-right-radius: 15px; border-bottom: 1px solid #E0E0E0;">
                                                Rechazados
                                            </th>
                                        </tr>
                                        <tr class="pa-0 ma-0">
                                            <th class="tbl2-subtitle">
                                                Reingresos
                                            </th>
                                            <th class="tbl2-subtitle">
                                                Modificaciones
                                            </th>
                                            <th class="tbl2-subtitle">
                                                Bajas
                                            </th>
                                            <th class="tbl2-subtitle">
                                                Total
                                            </th>
                                            <!-- *********** -->
                                            
                                            <th class="tbl2-subtitle border-left">
                                                Reingresos
                                            </th>
                                            <th class="tbl2-subtitle">
                                                Modificaciones
                                            </th>
                                            <th class="tbl2-subtitle">
                                                Bajas
                                            </th>
                                            <th class="tbl2-subtitle">
                                                Total
                                            </th>
                                            <!-- ********** -->

                                            <th class="tbl2-subtitle border-left">
                                                Reingresos
                                            </th>
                                            <th class="tbl2-subtitle">
                                                Modificaciones
                                            </th>
                                            <th class="tbl2-subtitle">
                                                Bajas
                                            </th>
                                            <th class="tbl2-subtitle">
                                                Total
                                            </th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td class="text-center py-2 tbl2-data">1</td>
                                            <td class="text-center py-2 tbl2-data">0</td>
                                            <td class="text-center py-2 tbl2-data">0</td>
                                            <td class="text-center py-2 tbl2-data">1</td>
                                            <td class="text-center py-2 tbl2-data">1</td>
                                            <td class="text-center py-2 tbl2-data">0</td>
                                            <td class="text-center py-2 tbl2-data">0</td>
                                            <td class="text-center py-2 tbl2-data">1</td>
                                            <td class="text-center py-2 tbl2-data">1</td>
                                            <td class="text-center py-2 tbl2-data">0</td>
                                            <td class="text-center py-2 tbl2-data">0</td>
                                            <td class="text-center py-2 tbl2-data">1</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </v-col>
                        </v-row>
                        
                        <v-row class="pa-0 ma-0 mt-6">
                            <v-col cols="12" xs="12" sm="12" md="12" class="px-5 py-2 d-flex justify-end align-center" style="min-width: 700px;">
                                <div 
                                    style="background-color: #EEEEEE; border-radius: 8px;"
                                    class="pa-2 d-flex justify-end align-center"
                                >  
                                    <div style="border-radius: 100%; background-color: #34C759; width: 20px; height: 20px" class="d-flex justify-end align-center mr-1">
                                        <v-icon small style="padding-right: 2px;" color="white">check</v-icon>
                                    </div>
                                    Movientos aceptados
                                </div>
                            </v-col>
                            <v-col cols="12" xs="12" sm="12" md="12" class="px-5 py-2 border-tabla2" style="min-width: 700px;">
                                <table class="tbl-collapse tbl2">
                                    <thead>
                                        <tr class="pa-0 ma-0">
                                            <th colspan="13" class="tbl2-title py-2" style="border-top-left-radius: 15px; border-top-right-radius: 15px; border-bottom: 1px solid #E0E0E0;">
                                                Movimientos aceptados
                                            </th>
                                        </tr>
                                        <tr class="pa-0 ma-0">
                                            <th class="tbl2-subtitle">
                                                #T.Mov.
                                            </th>
                                            <th class="tbl2-subtitle text-left">
                                                NSS
                                            </th>
                                            <th class="tbl2-subtitle text-left">
                                                Nombre
                                            </th>
                                            <th class="tbl2-subtitle">
                                                Salario
                                            </th>                                            
                                            <th class="tbl2-subtitle">
                                                Ext.
                                            </th>
                                            <th class="tbl2-subtitle">
                                                UMF
                                            </th>
                                            <th class="tbl2-subtitle">
                                                T. Salario
                                            </th>
                                            <th class="tbl2-subtitle">
                                                Fecha Mov.
                                            </th>
                                            <th class="tbl2-subtitle">
                                                T. Trab.
                                            </th>
                                            <th class="tbl2-subtitle">
                                                T. Jornada
                                            </th>
                                            <th class="tbl2-subtitle">
                                                T. Trabajador
                                            </th>
                                            <th class="tbl2-subtitle text-left">
                                                CURP
                                            </th>
                                            <th class="tbl2-subtitle">
                                                C. Baja
                                            </th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr :key="item.id" v-for="item in dataFake">
                                            <td class="text-center py-2 tbl2-data">{{ item.id }}</td>
                                            <td class="text-left py-2 tbl2-data">{{ item.nss }}</td>
                                            <td class="text-left py-2 tbl2-data">{{ item.nombre }}</td>
                                            <td class="text-right py-2 tbl2-data">{{ item.salario }}</td>
                                            <td class="text-center py-2 tbl2-data">{{ item.ext }}</td>
                                            <td class="text-center py-2 tbl2-data">{{ item.umf }}</td>
                                            <td class="text-right py-2 tbl2-data">{{ item.tsalario }}</td>
                                            <td class="text-center py-2 tbl2-data">{{ item.fecha_movimiento }}</td>
                                            <td class="text-center py-2 tbl2-data">{{ item.t_trab }}</td>
                                            <td class="text-center py-2 tbl2-data">{{ item.t_jornada }}</td>
                                            <td class="text-center py-2 tbl2-data">{{ item.t_trabajador }}</td>
                                            <td class="text-left py-2 tbl2-data">{{ item.curp }}</td>
                                            <td class="text-center py-2 tbl2-data">{{ item.c_baja }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </v-col>
                        </v-row>







                    </div>
                </v-col>
            </v-row>
        </v-container>

       
    </div>
</template>

<script>
import BtnAcciones from '@/components/BtnAcciones.vue';
import Notify from '@/plugins/notify';
import lotes from '../../js/lotes.js';

export default {
    components: {
        BtnAcciones,
    },
    data() {
        return {
            url         : "catalogo-sat/clases/find",
            columns     : [
                {
                    label: 'Registro Patronal',
                    name: 'registro_patronal',
                    filterable: true
                },
                {
                    label: 'Clave empleado',
                    name: 'clave_empleado',
                    filterable: true
                },
                {
                    label: 'Nombre',
                    name: 'nombre',
                    filterable: true
                },
                {
                    label: 'Apellido paterno',
                    name: 'apellido_paterno',
                    filterable: false
                },
                {
                    label: 'Apellido materno',
                    name: 'apellido_materno',
                    filterable: false
                },
                {
                    label: 'NSS',
                    name: 'nss',
                    filterable: false
                },
                {
                    label: 'SBC',
                    name: 'sbc',
                    filterable: false
                },
                {
                    label: 'Fecha de movimiento',
                    name: 'fecha_movimiento',
                    filterable: false
                },
                {
                    label: 'CURP',
                    name: 'curp',
                    filterable: false
                },
                {
                    label: 'UMF',
                    name: 'umf',
                    filterable: false
                },
                {
                    label: 'Tipo trabajador',
                    name: 'tipo_trabajador',
                    filterable: false
                },
                {
                    label: 'Tipo salario',
                    name: 'tipo_salario',
                    filterable: false
                },
                {
                    label: 'Jornada reducida',
                    name: 'jornada_reducida',
                    filterable: false
                },
                {
                    label: 'Estado',
                    name: 'estado',
                    filterable: false
                },
                {
                    label: 'Fecha de envío',
                    name: 'fecha_envio',
                    filterable: false
                },
                {
                    label: 'Acuse recibido',
                    name: 'acuse_recibido',
                    filterable: false
                },
                {
                    label: 'Acuse operado',
                    name: 'acuse_operado',
                    filterable: false
                },
                {
                    label: 'Acuse de rechazo',
                    name: 'acuse_rechazo',
                    filterable: false
                },
                {
                    label: 'Causa de rechazo',
                    name: 'causa_rechazo',
                    filterable: false
                },
            ],
            filters     : {
                activo    :true,
                paginate  :true,
                order     :"asc"
            },
            userData        : this.$session.get('usuario'),
            accion          : null,
            tituloModal     : null,
            imagen          : "",
            dialog          : false,
            isLoading       : false,
            isSaving        : false,
            masivo          : false,
            dataFake        : [],
            btnLotes   : 'btn1',
        }
    },
    methods: {
        exportar(tipo = 'txt'){

        },
        generarReporte(tipo = 'xlsx'){

        },
        abrirModalBuscar(){

        },
        filtroLotes(btn = 'btn1'){
            this.btnLotes = btn;
        }
    },
    
    mounted(){
        this.dataFake = lotes
    },
    updated(){
    }
}
</script>

<style scoped>
    .div-card {
        background-color: #fdfcfc;
        border-radius: 20px;
    }
    div.btnAccionesItem {
        width: 100%;
        cursor: pointer;
        outline: 0;
        padding-left: 30px;
        padding-right: 30px;
    }
    div.btnAccionesItem span {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        text-align: left;
        color: #828282;
    }
    div.btnAccionesItem:hover {
        background: #F6F6F8;
    }
    .deshabilitado{
        background:#fcf6f6;
        cursor:not-allowed;

        width: 100%;
        outline: 0;
        padding-left: 30px;
        padding-right: 30px;
    }
    div.deshabilitado:hover{
        background:#f2f2f2;
        cursor:not-allowed;

        width: 100%;
        outline: 0;
        padding-left: 30px;
        padding-right: 30px;
    }
    div.deshabilitado span {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        text-align: left;
        color: #828282;
    }
    .v-btn-excel::before {
        background-color: transparent;
    }
    .btn-lotes {
        background-color: #E4E4E5;
        height: 2.1875rem;
        border-radius: 8px;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        cursor: pointer;
    }
    .btn-activo {
        color: #444975;
    }
    .btn-inactivo {
        color: #828282;
    }

    .tbl-collapse {
        border-spacing: 0;
        width: 100%;
        overflow-x: scroll;
        overflow-y: scroll;
    }

    .border-tabla1 {
        border-width:1px; 
        border-color:#E0E0E0 ;
        border-style: solid;
        border-radius: 15px;
    }

    .border-left {
        border-width:1px; 
        border-color:#E0E0E0 ;
        border-left-style: solid;
    }

    .tbl1-title {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        color: #828282;
        text-align: left;
    }
    .tbl1-subtitle {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        color: #4F4F4F;
        text-align: left;
    }

    .tbl2 {
        -moz-border-radius:15px;
        -webkit-border-radius:15px;
        border-radius:15px;
        border: 1px solid #E0E0E0;
    }

    .tbl2-title {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        color: #828282;
        text-align: center;
        background-color: #F2F2F2;
    }
    .tbl2-subtitle {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: #828282;
        text-align: center;
        background-color: #F6F6F6;
        padding: 8px 0;
    }

    .tbl2-data {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: #1D1B20;
    }
    

</style>
